<template>
    <div class="fjListPart">
        <div class="imgPart flex flex-wrap" v-viewer>
            <div
                v-for="(item, index) in fileData.imgList"
                :key="index"
                class="imgItem flex"
            >
                <el-image :src="item.path" fit="cover" class="pointer img">
                    <div slot="error" style="width: 100%; height: 100%">
                        <SnNoData typeNum="1" width="80px"></SnNoData>
                    </div>
                </el-image>
                <div class="desc scrollbar flex-1 pointer" :title="item.fjsm">
                    {{ item.fjsm }}
                </div>
            </div>
        </div>
        <div class="audioPart flex flex-wrap" v-if="fileData.mp3List.length">
            <audio
                v-for="(item, index) in fileData.mp3List"
                :key="index"
                class="audioItem"
                :src="item.path"
                controls
            ></audio>
        </div>
        <div class="videoPart flex flex-wrap" v-if="fileData.mp4List.length">
            <div v-for="(item, index) in fileData.mp4List" :key="index">
                <video :src="item.path" controls class="videoItem"></video>
                <div>
                    {{ item.fjsm }}
                </div>
            </div>
        </div>

        <div class="filePart scrollbar" v-if="fileData.othersPart.list.length">
            <div
                class="flex flex-y-center fileItem pointer"
                v-for="(item, index) in fileData.othersPart.list"
                :key="index"
                @click="getFileItem(item[fileData.othersPart.key])"
            >
                <el-image
                    :src="filterFileType(item[fileData.othersPart.key])"
                    fit="cover"
                    class="pointer img"
                >
                </el-image>
                <div>
                    {{ item[fileData.othersPart.name] }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    name: "",
    data() {
        return {};
    },
    computed: {},
    props: {
        fileData: {
            type: Object,
            default() {
                let obj = {
                    mp3List: [],
                    mp4List: [],
                    imgList: [],
                    othersPart: {
                        key: "CCLJ",
                        name: "WDMC",
                        list: [],
                    },
                };
                return obj;
            },
        },
    },
    watch: {},
    mounted() {},
    methods: {
        // 获取文件后缀
        filterFileType(value) {
            if (value) {
                let type = value
                    .slice(value.lastIndexOf(".") + 1)
                    .toLowerCase();
                let typeArr = [
                    "dwg",
                    "exl",
                    "pdf",
                    "rar",
                    "shp",
                    "tiff",
                    "txt",
                    "xls",
                    "xlsx",
                    "zip",
                ];
                type = typeArr.indexOf(type) > -1 ? type : "normal";
                let src = require("@image/file/" + type + ".png");
                return src;
            }
        },
        getFileItem(url) {
            window.open(url);
        },
    },
};
</script>
<style scoped lang="scss">
.fjListPart {
    width: 100%;
    height: 100%;
    .imgPart {
        .imgItem {
            width: 190px;
            height: 104px;
            margin-right: 4px;
            margin-bottom: 4px;
            border: 1px solid #eee;
            .img {
                width: 100px;
                height: 100px;
                margin-right: 4px;
            }
            .desc {
            }
        }
    }
    .audioPart {
        margin-top: 10px;
        .audioItem {
            width: 270px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    .videoPart {
        margin-top: 10px;
        .videoItem {
            width: 200px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    .filePart {
        margin-top: 10px;
        max-height: 200px;
        margin-top: 10px;
        .fileItem {
            height: 45px;
            border-bottom: 1px solid #eee;
            color: #606266;
            .img {
                width: 25px;
                margin-right: 10px;
            }
        }
    }
}
</style>
