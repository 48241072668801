<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">{{ title }}</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="名称" prop="MC" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.MC"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="类型" prop="LX" :style="{ width: '80%' }">
                    <el-select
                        v-model="formdata.dataDic.LX"
                        :disabled="!isEdit"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="{ CODE, LABEL } in enumObj.LX"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="说明" prop="SM" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.SM"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="价值" prop="JZ" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.JZ"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="相关内容" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.XGNR"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="附件" prop="" :style="{ width: '80%' }">
                    <fileDialog :fileData="fileData"> </fileDialog>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../../0_com_js/info_mixin.js";
import fileDialog from "./comp/file_dialog";
export default {
    mixins: [infoMixin],
    components: {
        fileDialog,
    },
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            title: "",
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    MC: null, //名称
                    LX: null,
                    SM: null, //说明
                    JZ: null, //价值
                    XGNR: null, //相关内容
                },
                itemCode: "500202",
                metaJson: [],
            },
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "",
                    name: "",
                    list: [],
                },
            },
            rules: {
                MC: [
                    {
                        required: true,
                        message: "请填写名称",
                        trigger: ["blur"],
                    },
                ],
            },
        };
    },
    watch: {},
    mounted() {
        this.title = this.$route.meta.title;
    },
    methods: {
        GetDataNext() {
            this.fileData.mp3List = [];
            this.fileData.mp4List = [];
            this.fileData.imgList = [];
            this.formdata.PIC.map((item) => {
                let dw = item.path.substring(item.path.lastIndexOf(".") + 1);
                switch (dw) {
                    case "mp3":
                        this.fileData.mp3List.push(item);
                        break;
                    case "mp4":
                        this.fileData.mp4List.push(item);
                        break;
                    case "jpg":
                    case "png":
                    case "jpeg":
                    case "gif":
                    case "tiff":
                        this.fileData.imgList.push(item);

                        break;
                    default:
                        this.fileData.othersPart.list.push(item);
                        break;
                }
            });
            console.log(
                "%c 🍨 this.fileData: ",
                "font-size:20px;background-color: #E41A6A;color:#fff;",
                this.fileData
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.fjPart {
    height: 230px;
    margin-bottom: 30px;
    .fjItem {
        width: 217px;
        height: 230px;
        margin-bottom: 30px;
        margin-right: 30px;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}

.videoPart {
    height: 230px;
    margin-bottom: 30px;
    .videoItem {
        width: 217px;
        height: 230px;
        margin-right: 30px;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}

.mp3Part {
    .mp3Item {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
</style>